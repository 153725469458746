<template>
  <div>
    <v-divider></v-divider>
    <v-data-table
      :footer-props="{
        'items-per-page-text': $vuetify.breakpoint.smAndDown
          ? ''
          : $t('items_per_page'),
      }"
      :items="items"
      :headers="headers"
      :options.sync="options"
      :server-items-length="total"
    >
      <template v-slot:item.price="{ item }">{{
        $n(item.appointments[0].price, "currency")
      }}</template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "StudioReport",
  data() {
    return {
      items: [],
      headers: [
        { text: this.$tc("studio"), sortable: false, value: "studio_name" },
        {
          text: this.$tc("appointment", 2),
          sortable: false,
          value: "appointments[0].total",
        },
        {
          text: this.$t("appointments.price"),
          sortable: false,
          value: "price",
        },
      ],
      options: {},
      total: null,
    };
  },
  methods: {
    ...mapActions("reports", ["getStudiosReport"]),
    fetchStudioReport() {
      this.getStudiosReport({ options: this.options }).then((data) => {
        this.items = data.data;
        this.total = data.total;
      });
    },
  },
  mounted() {
    this.fetchStudioReport();
  },
  watch: {
    options: {
      handler() {
        this.fetchStudioReport();
      },
      deep: true,
    },
  },
};
</script>

<style lang="sass">
</style>